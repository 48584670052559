import React, { Component } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from 'styled-components';
import Img from "gatsby-image";
import Truncate from 'react-truncate';


class ArticleTeaser extends Component {
    render(props) {
        const article = this.props.node;
        const bodyText = renderRichText(article.body);

        return(
            <StyledLink href={`/articles/${article.slug}`}>  
                <ImageWrapper> 
                    <Img fixed={article.images[0].fixed} />
                </ImageWrapper>

                <TagWrapper>
                    {article.tags[0].title}
                </TagWrapper>

                <h2 key={article.id}>{article.title}</h2>

                <div className="teaser-text">
                    <Truncate
                        lines={1}
                        width={500}
                        ellipsis='&hellip;'
                        >
                        {bodyText}
                    </Truncate>
                </div>

                <StyledButton>Read more</StyledButton> 

            </StyledLink>
        );
    }
}

export default ArticleTeaser;


const StyledLink = styled.a`
    &:hover {
        text-decoration: none !important;
    }

    h2 {
        font-size: 18px;
        font-weight: 400;
        color: black;
    }

    .teaser-text {
        color: #b9b9b9;
    }
`

const StyledButton = styled.button`
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    line-height: 1em;
    appearance: none;
    background: transparent;
    border: 2px solid orange;
    outline: none;
    border-radius: 5px;
    color: orange;
    transition: all 0.5s;
    display: block;

    &:hover {
        cursor: pointer;
        background: orange;
        color: #fff;
    }
`

const TagWrapper = styled.div`
    margin: 10px 0;
    border-top: 1px solid #d8cfcf;
    border-bottom: 1px solid #d8cfcf;
    padding: 10px 0;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-size: 12px;
`

const ImageWrapper = styled.div`
    max-width: 100%;
	
    .gatsby-image-wrapper {
		margin: 0 !important;
        width: auto !important;
        max-width: 300px;
        display: block !important;
        border-radius: 5px;

    @media(max-width: 650px) {
      width: 300px !important;
      height: 200px !important;
      margin: 10px 0 !important;
    }
}
`