import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';
import ArticleTeaser from 'components/article/ArticleTeaser';
import styled from 'styled-components';

class Articles extends Component {

  render(props) {
    const bgImage = this.props.data.file.childImageSharp.fluid.src;
    const articles = this.props.data.articles.nodes;

  	return(

  		<Layout pageName="Best in Brighton Articles">
	      <Helmet>
	        <title>Best in Brighton articles</title>
	      </Helmet>
	      <div
	        className="page-hero"
	        style={{
	          backgroundImage: `url(${bgImage})`,
	        }}
	      >
	        <h1>Best in Brighton articles</h1>
	      </div>

	      <div className="container container-content">
          <p>Browse best in Brighton's articles, from things to do in Brighton to the top 5 coffee shops to visit.</p>

          <Wrapper> 
          <ArticleList>
            {articles.map(article => {
                  return(
                      <li key={article.id}>
                        <ArticleTeaser node={article} />
                      </li>
                  );
              })}
            </ArticleList>
            </Wrapper>   
	      </div>
  		</Layout>
  	);
  }
}


export default Articles

const Wrapper = styled.div`
`

const ArticleList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  li {
    padding: 10px;
    flex: 0 0 33%;
  }
`

export const query = graphql`
  query {
    file(relativePath: { eq: "banner-images/articles.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, fit: COVER) {
        	src
          ...GatsbyImageSharpFluid
        }
      }
    }

    articles: allContentfulArticle(sort: {order: DESC, fields: createdAt}) {
        nodes {
          id
          title
          slug
          images {
            fixed(width: 300, height: 200) {
              ...GatsbyContentfulFixed
            } 
          }
          body {
            raw
          }
          tags {
            title
          }
          createdAt(formatString: "D-M-Y")
        }
      }
  }
`